import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ROUTES } from '@/app/pages/routes';
import { FormForEntry } from '../FormForEntry';
import { FormResetPasswordTitle } from './FormResetPasswordTitle/FormResetPasswordTitle';
import { LinkIsExpired } from './LinkIsExpired/LinkIsExpired';
export const FormResetPassword = ({ isExpired, error, onSubmit, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    if (isExpired)
        return (_jsx(LinkIsExpired, { subtitle: t('Ссылка актуальна 24 часа'), buttonText: t('Вернуться на страницу входа'), onClick: () => navigate(ROUTES.LOGIN, {
                replace: true,
            }) }));
    const rules = {
        minLength: {
            value: 8,
            message: t('FIELD_MIN_LENGTH_ERROR', {
                amount: 8,
                count: 8,
            }),
        },
    };
    const repeatPasswordRules = (watch) => ({
        matchesPasswords: (value) => {
            return value === watch('password') || t('Пароли должны совпадать');
        },
    });
    return (_jsx(FormForEntry, { firstField: {
            name: 'password',
            placeholder: t('новый пароль'),
            type: 'password',
            rules,
        }, secondField: {
            name: 'repeatPassword',
            placeholder: t('повторите пароль'),
            type: 'password',
            rules,
            validates: repeatPasswordRules,
        }, submitButtonText: t('Сохранить и войти'), FormTitle: _jsx(FormResetPasswordTitle, {}), onSubmit: onSubmit, error: error }));
};
