export function wrapElementWith(element, tag) {
    const el = document.createElement(tag);
    el.appendChild(element);
    return el;
}
export function textFormatter(tag, thisArg) {
    let element = document.createElement(tag);
    if (thisArg.hasFormat('bold'))
        element = wrapElementWith(element, 'b');
    if (thisArg.hasFormat('italic'))
        element = wrapElementWith(element, 'i');
    if (thisArg.hasFormat('strikethrough'))
        element = wrapElementWith(element, 's');
    if (thisArg.hasFormat('underline'))
        element = wrapElementWith(element, 'u');
    // тут кейс с тегом code используем как спойлер в телеграме
    if (thisArg.hasFormat('code'))
        element = wrapElementWith(element, 'tg-spoiler');
    return element;
}
