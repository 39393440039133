import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes } from 'lexical';
import { useEffect } from 'react';
export const EditorInitStatePlugin = ({ initialValue, }) => {
    const [editor] = useLexicalComposerContext();
    // Инициализация состояния редактора
    useEffect(() => {
        editor === null || editor === void 0 ? void 0 : editor.update(() => {
            // Парсим строку разметки в DOM документ
            const dom = new DOMParser().parseFromString(initialValue.replaceAll(/<tg-spoiler[^>]*>([^<]*)<\/tg-spoiler>/g, '<code>$1</code>'), 'text/html');
            // Генерируем узлы lexical из DOM
            const nodes = $generateNodesFromDOM(editor, dom);
            // Вставляем узлы в редактор
            $insertNodes(nodes);
        });
    }, []);
    return null;
};
