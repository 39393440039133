import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { paginationSelector } from '@/store/selectors/appPeriod';
import { changeOffset } from '@/store/slice/appPeriod';
export const usePaginationState = () => {
    const dispatch = useAppDispatch();
    const { purchases, orders } = useAppSelector(paginationSelector);
    const setPurchasesOffset = useCallback((offset) => dispatch(changeOffset({ list: 'purchases', offset })), []);
    const resetPurchasesOffset = useCallback(() => {
        setPurchasesOffset(0);
    }, []);
    const setOrdersOffset = useCallback((offset) => dispatch(changeOffset({ list: 'orders', offset })), []);
    const resetOrdersOffset = useCallback(() => {
        setOrdersOffset(0);
    }, []);
    const resetPagination = useCallback(() => {
        resetPurchasesOffset();
        resetOrdersOffset();
    }, []);
    return {
        pagination: {
            purchases: {
                limit: purchases.limit,
                offset: purchases.offset,
                setOffset: setPurchasesOffset,
                resetOffset: resetPurchasesOffset,
            },
            orders: {
                limit: orders.limit,
                offset: orders.offset,
                setOffset: setOrdersOffset,
                resetOffset: resetOrdersOffset,
            },
        },
        resetPagination,
    };
};
