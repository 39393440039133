import { useGetOrderListQuery } from '@/api/query/orders';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, paginationSelector, } from '@/store/selectors/appPeriod';
export const useOrders = ({ filters, searchText, }) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { orders: { limit, offset }, } = useAppSelector(paginationSelector);
    const { data, isLoading, isFetching, refetch } = useGetOrderListQuery({ filters, searchText, periodStart, periodEnd, limit, offset }, { refetchOnMountOrArgChange: true });
    return {
        items: data || [],
        isLoading,
        isFetching,
        refetch,
    };
};
