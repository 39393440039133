import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { FormForEntryPanel } from './FormForEntryPanel';
import styles from './styles.module.scss';
export const FormForEntry = ({ firstField, secondField, submitButtonText, error, FormTitle, onSubmit, }) => {
    var _a, _b;
    const firstFieldName = firstField.name;
    const secondFieldName = secondField.name;
    const firstFieldRules = firstField.rules || {};
    const secondFieldRules = secondField.rules || {};
    const secondFieldValidates = secondField.validates || (() => ({}));
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { isSubmitting, errors }, watch, } = useForm({
        defaultValues: {
            [firstFieldName]: '',
            [secondFieldName]: '',
        },
    });
    const validateTrimValue = (value) => !value.trim() ? t('FIELD_REQUIRED_ERROR') : true;
    return (_jsx(FormForEntryPanel, Object.assign({ title: FormTitle }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit((data) => onSubmit(data)) }, { children: [_jsx(FormFieldRow, Object.assign({ className: styles.control, help: (_a = errors[firstFieldName]) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors[firstFieldName], hasLabelHidden: true }, { children: _jsx(Controller, { name: firstFieldName, control: control, rules: Object.assign({ validate: {
                                validateTrimValue,
                            } }, firstFieldRules), render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: firstFieldName, type: firstField.type || 'text', placeholder: firstField.placeholder, hasError: !!errors[firstFieldName], autoFocus: true }))) }) })), _jsx(FormFieldRow, Object.assign({ className: styles.control, help: (_b = errors[secondFieldName]) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors[secondFieldName], hasLabelHidden: true }, { children: _jsx(Controller, { name: secondFieldName, control: control, rules: Object.assign({ validate: Object.assign({ validateTrimValue }, secondFieldValidates(watch)) }, secondFieldRules), render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: secondFieldName, type: secondField.type || 'password', placeholder: secondField.placeholder, hasError: !!errors[secondFieldName] }))) }) })), error && (_jsx(FormFieldRow, Object.assign({ className: styles.error, asErrorMessage: true, hasLabelHidden: true }, { children: error }))), _jsx(Button, Object.assign({ type: 'submit', theme: 'primary', disabled: isSubmitting, size: 'full-width' }, { children: submitButtonText }))] })) })));
};
