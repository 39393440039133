import { createContext } from 'react';
const noop = () => { };
const noopPromise = () => Promise.resolve();
export const PurchasesContext = createContext({
    tabs: {
        activeTab: 'purchases',
        setActiveTab: noop,
    },
    pagination: {
        purchases: {
            limit: 100,
            offset: 0,
            setOffset: noop,
            resetOffset: noop,
        },
        orders: {
            limit: 100,
            offset: 0,
            setOffset: noop,
            resetOffset: noop,
        },
    },
    search: {
        searchText: '',
        apply: noop,
    },
    filters: {
        filtersTree: [],
        filters: [],
        update: noop,
        reset: noop,
    },
    data: {
        count: {
            purchase: 0,
            order: 0,
            isLoading: false,
            isFetching: false,
            refetch: noop,
        },
        purchases: {
            items: [],
            isLoading: false,
            isFetching: false,
            refetch: noop,
        },
        orders: {
            items: [],
            isLoading: false,
            isFetching: false,
            refetch: noop,
        },
    },
    selectedPurchase: {
        index: null,
        data: undefined,
        setIndex: noop,
    },
    selectedOrder: {
        index: null,
        data: undefined,
        setIndex: noop,
    },
    modals: {
        purchases: {
            detailsModal: {
                isOpen: false,
                open: noop,
                close: noop,
            },
            addModal: {
                isOpen: false,
                open: noop,
                close: noop,
            },
        },
        orders: {
            detailsModal: {
                isOpen: false,
                open: noop,
                close: noop,
            },
        },
    },
    exportData: {
        exportPurchases: noopPromise,
    },
});
