import { $createLinkNode, $isLinkNode, toggleLink } from '@lexical/link';
import { $createTextNode, $getSelection, $insertNodes, $isRangeSelection, } from 'lexical';
import { useCallback, useEffect, useState } from 'react';
const INITITAL_LINK_DATA = {
    title: '',
    href: '',
};
export const useLinkPopup = (editorRef) => {
    const [isOpenLinkPopup, setIsOpenLinkPopup] = useState(false);
    const [linkData, setLinkData] = useState(INITITAL_LINK_DATA);
    const [editingLink, setEditingLink] = useState(null);
    const [isCreateEmptyLink, setIsCreateEmptyLink] = useState(true);
    const [isLinkIntoSelection, setIsLinkIntoSelection] = useState(false);
    const [isLink, setIsLink] = useState(false);
    // Отлов данных ссылки если каретка находится на нем
    useEffect(() => {
        return editorRef === null || editorRef === void 0 ? void 0 : editorRef.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const selection = $getSelection();
                if (!$isRangeSelection(selection))
                    return;
                const nodes = selection.getNodes();
                const parent = nodes[0].getParent();
                const isLinkInSelection = nodes.some($isLinkNode);
                setIsLinkIntoSelection(isLinkInSelection);
                if ($isLinkNode(parent)) {
                    setIsCreateEmptyLink(false);
                    setIsLink(true);
                }
                else if (isLinkInSelection) {
                    setIsCreateEmptyLink(false);
                    setIsLink(true);
                }
                else
                    setIsLink(false);
            });
        });
    }, [editorRef]);
    // toggleLink применяется на основании того где стоит каретка - он берет элемент по $getSelection(),
    // т.к каретку нельзя поставить на VariableNode но переменная может быть внутри ссылки,
    // то получаем ссылку на LinkNode в LinkPlugin и работаем с ней вручную
    const handleDeleteLinkNode = () => {
        editorRef === null || editorRef === void 0 ? void 0 : editorRef.update(() => {
            // Удаление LinkNode
            if ($isLinkNode(editingLink)) {
                const children = editingLink.getChildren();
                for (let i = 0; i < children.length; i++)
                    editingLink.insertBefore(children[i]);
                editingLink.remove();
            }
        });
    };
    const onChangeLinkData = (value, fieldName) => setLinkData((prevLinkData) => (Object.assign(Object.assign({}, prevLinkData), { [fieldName]: value })));
    const handleCloseLinkPopup = () => {
        setLinkData(INITITAL_LINK_DATA);
        setIsOpenLinkPopup(false);
        setEditingLink(null);
        setIsCreateEmptyLink(true);
        editorRef === null || editorRef === void 0 ? void 0 : editorRef.focus();
    };
    // Если каретка над ссылкой (LinkNode родитель) то берем ее данные
    // Если ссылка в числе выбранных то тоглим ссылку и берем текст диапазона
    const onOpenLinkPopup = useCallback(() => {
        editorRef === null || editorRef === void 0 ? void 0 : editorRef.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const nodes = selection.getNodes();
                const parent = nodes[0].getParent();
                if (isLinkIntoSelection) {
                    toggleLink(null);
                    onChangeLinkData(selection.getTextContent(), 'title');
                    setIsCreateEmptyLink(false);
                    return;
                }
                if (!isLink && !isLinkIntoSelection) {
                    onChangeLinkData(selection.getTextContent(), 'title');
                    setIsCreateEmptyLink(false);
                    if (selection.isCollapsed())
                        setIsCreateEmptyLink(true);
                    return;
                }
                if (isLink && $isLinkNode(parent)) {
                    setEditingLink(parent);
                    onChangeLinkData(parent.getTextContent(), 'title');
                    onChangeLinkData(parent.getURL(), 'href');
                    setIsCreateEmptyLink(false);
                    return;
                }
            }
        });
        setIsOpenLinkPopup(true);
    }, [onChangeLinkData]);
    const onClickLink = (node) => {
        setIsCreateEmptyLink(false);
        setEditingLink(node);
        onChangeLinkData(node.getTextContent(), 'title');
        onChangeLinkData(node.getURL(), 'href');
        setIsOpenLinkPopup(true);
    };
    const handleDeleteLink = () => {
        handleDeleteLinkNode();
        handleCloseLinkPopup();
    };
    const handleCreateLink = () => {
        editorRef === null || editorRef === void 0 ? void 0 : editorRef.update(() => {
            // Редактирование LinkNode при клике на нее
            if (editingLink) {
                editingLink === null || editingLink === void 0 ? void 0 : editingLink.setURL(linkData.href);
                return;
            }
            // Создание новой LinkNode
            if (isCreateEmptyLink) {
                const linkNode = $createLinkNode(linkData.href);
                linkNode.append($createTextNode(linkData.title));
                $insertNodes([linkNode]);
                return;
            }
            // Создание LinkNode внутри диапазона выделения
            toggleLink(linkData.href);
        });
    };
    return {
        isLink,
        isCreateEmptyLink,
        isOpenLinkPopup,
        linkHref: linkData.href,
        linkTitle: linkData.title,
        handleCloseLinkPopup,
        onChangeLinkData,
        handleCreateLink,
        onOpenLinkPopup,
        handleDeleteLink,
        onClickLink,
    };
};
