import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createQuoteNode, $isQuoteNode } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import classNames from 'classnames';
import { $createParagraphNode, $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, } from 'lexical';
import { Button } from '@/components/Button2/Button';
import { CheckCircle } from '@/icons/CheckCircle';
import { Quote } from '@/icons/Quote';
import themeStyles from '../themeStyles.module.scss';
import styles from './styles.module.scss';
export const OptionsPopup = ({ isBold, isItalic, isQuote, isSpoiler, isUnderline, isStrikethrough, getFormatsFromSelection, setIsQuote, }) => {
    const [editor] = useLexicalComposerContext();
    const toggleQuote = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                // Обращаемся к первой ноде и получаем все родительские ноды
                const firstNodeParents = selection.anchor.getNode().getParents();
                const quoteNode = firstNodeParents === null || firstNodeParents === void 0 ? void 0 : firstNodeParents.find($isQuoteNode);
                // Если цитата есть, то удаляем ее и заменяем на параграф
                if (quoteNode) {
                    const children = quoteNode.getChildren();
                    // При добавлении цитаты, параграф заменяется цитатой.
                    // Текстовые ноды нельзя добавить в корневой узел поэтому заменяем ее на новый параграф
                    const paragraph = $createParagraphNode();
                    for (let i = 0; i < children.length; i++)
                        paragraph.append(children[i]);
                    quoteNode.insertBefore(paragraph);
                    quoteNode.remove();
                    setIsQuote(false);
                }
                else {
                    $setBlocksType(selection, () => $createQuoteNode());
                    setIsQuote(true);
                }
            }
        });
    };
    const handleFormat = (format) => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
        getFormatsFromSelection(format, true);
    };
    return (_jsxs("ul", Object.assign({ className: styles.optionsList }, { children: [_jsxs(Button, Object.assign({ onClick: () => handleFormat('bold'), className: classNames(styles.optionsListButtom, themeStyles.bold), "aria-label": 'Format Bold' }, { children: ["\u0416\u0438\u0440\u043D\u044B\u0439 ", isBold && _jsx(CheckCircle, {})] })), _jsxs(Button, Object.assign({ className: classNames(styles.optionsListButtom, themeStyles.underline), onClick: () => handleFormat('underline') }, { children: ["\u041F\u043E\u0434\u0447\u0435\u0440\u043A\u043D\u0443\u0442\u044B\u0439 ", isUnderline && _jsx(CheckCircle, {})] })), _jsxs(Button, Object.assign({ className: classNames(styles.optionsListButtom, themeStyles.strikethrough), onClick: () => handleFormat('strikethrough') }, { children: ["\u0417\u0430\u0447\u0435\u0440\u043A\u043D\u0443\u0442\u044B\u0439 ", isStrikethrough && _jsx(CheckCircle, {})] })), _jsxs(Button, Object.assign({ className: classNames(styles.optionsListButtom, themeStyles.italics), onClick: () => handleFormat('italic') }, { children: ["\u041A\u0443\u0440\u0441\u0438\u0432 ", isItalic && _jsx(CheckCircle, {})] })), _jsxs(Button, Object.assign({ className: classNames(styles.optionsListButtom), onClick: () => handleFormat('code') }, { children: [_jsx("span", Object.assign({ className: themeStyles.hidden }, { children: "\u0421\u043A\u0440\u044B\u0442\u044B\u0439" })), isSpoiler && _jsx(CheckCircle, {})] })), _jsxs(Button, Object.assign({ className: classNames(styles.optionsListButtom), onClick: toggleQuote }, { children: [_jsxs("div", Object.assign({ className: styles.quote }, { children: ["\u0426\u0438\u0442\u0430\u0442\u0430", _jsx(Quote, {})] })), isQuote && _jsx(CheckCircle, {})] }))] })));
};
