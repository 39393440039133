import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NewStepNodeSteps } from './NewStepNodeSteps';
import { NewStepNodeTriggerFilters } from './NewStepNodeTriggerFilters';
import styles from './styles.module.scss';
export const NewStepNode = ({ data, }) => {
    const { t } = useTranslation();
    const { triggerFilters = [], steps = [], onSelectStep } = data;
    const handleSelectStep = useCallback((step) => {
        onSelectStep === null || onSelectStep === void 0 ? void 0 : onSelectStep(step);
    }, [onSelectStep]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t('Добавьте шаг') })), _jsx(NewStepNodeTriggerFilters, { filters: triggerFilters, onSelectFilter: handleSelectStep }), _jsx(NewStepNodeSteps, { steps: steps, onSelectStep: handleSelectStep })] })), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target'), type: 'target', position: Position.Left })] }));
};
