import moment from 'moment';
import * as triggers from '@/const/mailing-triggers';
import { OFFSET_SIGN_AT, OFFSET_SIGN_SCHEDULE, } from '@/forms/FormMailing/controls/FormMailingOffset';
import { compileFilters } from '@/services/filters/compileFilters';
export const convertFormValuesToMailing = (type, values, isDraft = false) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const recipients = {
        all: !!values.recipientAllClients,
        filters: {
            conditions: compileFilters(values.recipientFilters || []),
            configuration: values.recipientFilters || [],
        },
        segments: [],
    };
    const excludeRecipients = {
        segments: [],
    };
    if (recipients.filters.conditions.clientSegmentsEq)
        recipients.segments = Array.isArray(recipients.filters.conditions.clientSegmentsEq)
            ? recipients.filters.conditions.clientSegmentsEq
            : [recipients.filters.conditions.clientSegmentsEq];
    if (recipients.filters.conditions._not &&
        recipients.filters.conditions._not.clientSegmentsEq)
        excludeRecipients.segments = Array.isArray(recipients.filters.conditions._not.clientSegmentsEq)
            ? recipients.filters.conditions._not.clientSegmentsEq
            : [recipients.filters.conditions._not.clientSegmentsEq];
    let offsetSign = values.offsetSign;
    let offsetValue = values.offsetValue;
    let offsetUnit = values.offsetUnit;
    let triggerType = values.triggerType;
    const triggerFilters = typeof triggerType === 'undefined'
        ? []
        : ((_a = values.triggerFilters) === null || _a === void 0 ? void 0 : _a[triggerType]) || [];
    const triggerConditions = {
        conditions: compileFilters(triggerFilters),
        configuration: triggerFilters,
    };
    if (offsetSign === OFFSET_SIGN_SCHEDULE) {
        offsetSign = OFFSET_SIGN_AT;
        offsetValue = undefined;
        offsetUnit = undefined;
        triggerType = triggers.TRIGGER_TYPE_SCHEDULE;
        triggerConditions.conditions = {
            scheduleType: values.triggerScheduleType,
            scheduleTime: values.triggerScheduleTime,
            scheduleWeekdays: (_b = values.triggerScheduleDaysOfWeek) === null || _b === void 0 ? void 0 : _b.reduce((sum, w) => sum + w, 0),
            scheduleDays: values.triggerScheduleDaysOfMonth,
            scheduleDate: values.triggerScheduleDate
                ? moment(values.triggerScheduleDate).format('YYYY-MM-DD')
                : undefined,
        };
    }
    if (triggerType === triggers.TRIGGER_TYPE_EMAIL_DELIVERED)
        triggerConditions.conditions = {
            ifMailing: values.triggerEmailReceivedType,
            mailingBrandEq: values.triggerEmailReceivedMailingId,
        };
    const scheduleDate = values.scheduleDate
        ? moment(values.scheduleDate).format('DD.MM.YYYY')
        : undefined;
    let scheduleTimeHours = values.scheduleTime
        ? values.scheduleTime.split(':')[0]
        : undefined;
    if (scheduleTimeHours === null || scheduleTimeHours === void 0 ? void 0 : scheduleTimeHours.startsWith('0'))
        scheduleTimeHours = scheduleTimeHours.slice(1);
    let scheduleTimeMinutes = values.scheduleTime
        ? values.scheduleTime.split(':')[1]
        : undefined;
    if (scheduleTimeMinutes === null || scheduleTimeMinutes === void 0 ? void 0 : scheduleTimeMinutes.startsWith('0'))
        scheduleTimeMinutes = scheduleTimeMinutes.slice(1);
    let walletImageId = !(values.walletImage instanceof File)
        ? (_c = values.walletImage) === null || _c === void 0 ? void 0 : _c.id
        : undefined;
    if ((_d = values.walletImageData) === null || _d === void 0 ? void 0 : _d.id)
        walletImageId = values.walletImageData.id;
    let viberImageId = !(values.viberImage instanceof File)
        ? (_e = values.viberImage) === null || _e === void 0 ? void 0 : _e.id
        : undefined;
    if ((_f = values.viberImageData) === null || _f === void 0 ? void 0 : _f.id)
        viberImageId = values.viberImageData.id;
    let telegramImageId = !(values.telegramImage instanceof File)
        ? (_g = values.telegramImage) === null || _g === void 0 ? void 0 : _g.id
        : undefined;
    if ((_h = values.telegramImageData) === null || _h === void 0 ? void 0 : _h.id)
        telegramImageId = values.telegramImageData.id;
    const payload = {
        id: values.id,
        type: type === 'manual' ? 1 : 0,
        name: values.name,
        channels: {
            push: !!((_j = values.channels) === null || _j === void 0 ? void 0 : _j.includes('push')),
            viber: !!((_k = values.channels) === null || _k === void 0 ? void 0 : _k.includes('viber')),
            sms: !!((_l = values.channels) === null || _l === void 0 ? void 0 : _l.includes('sms')),
            email: !!((_m = values.channels) === null || _m === void 0 ? void 0 : _m.includes('email')),
            telegram: !!((_o = values.channels) === null || _o === void 0 ? void 0 : _o.includes('telegram')),
        },
        recipientAllClients: recipients.all,
        recipients,
        excludeRecipients,
        // trigger (automatic only)
        offsetSign,
        offsetValue,
        offsetUnit,
        triggerType: typeof triggerType !== 'undefined' ? triggerType.toString() : undefined,
        triggerConditions,
        repeat: values.repeat,
        // schedule (manual only)
        schedule: values.schedule,
        scheduleDate,
        scheduleTimeHours,
        scheduleTimeMinutes,
        // rewards
        bonusesAmount: ((_p = values.rewards) === null || _p === void 0 ? void 0 : _p.bonusesAmount)
            ? Number((_q = values.rewards) === null || _q === void 0 ? void 0 : _q.bonusesAmount)
            : undefined,
        bonusesLifetime: ((_r = values.rewards) === null || _r === void 0 ? void 0 : _r.bonusesLifetime)
            ? Number((_s = values.rewards) === null || _s === void 0 ? void 0 : _s.bonusesLifetime)
            : undefined,
        promocodeId: (_t = values.rewards) === null || _t === void 0 ? void 0 : _t.promocodeId,
        giftCardId: (_u = values.rewards) === null || _u === void 0 ? void 0 : _u.giftCardId,
        offers: ((_v = values.rewards) === null || _v === void 0 ? void 0 : _v.offers)
            ? (_w = values.rewards) === null || _w === void 0 ? void 0 : _w.offers.map((o) => ({
                id: o.id,
                expireInDays: o.expireInDays ? Number(o.expireInDays) : null,
            }))
            : undefined,
        // push
        messagingAppId: values.messagingApp,
        pushText: values.pushText,
        walletPushExpireInDays: typeof values.walletPushExpireInDays !== 'undefined'
            ? values.walletPushExpireInDays.toString()
            : undefined,
        walletImageId,
        // viber
        viberText: values.viberText,
        viberButton: values.viberButton,
        viberUrl: values.viberUrl,
        viberImageId,
        // sms
        smsSenderId: values.smsSender,
        smsText: values.smsText,
        // email
        emailSenderId: values.emailSender,
        emailSubject: values.emailSubject,
        emailBody: values.emailBody,
        emailDesign: values.emailDesign,
        emailPreviewImageUrl: values.emailPreviewImageUrl,
        includeRecommendations: values.includeRecommendations,
        recommendationType: values.recommendationType,
        recommendationCount: values.recommendationCount,
        // telegram
        telegramText: values.telegramText,
        telegramButton: values.telegramButton,
        telegramUrl: values.telegramUrl,
        telegramImageId,
        goalType: values.goalType,
        goalDaysTo: values.goalDaysTo,
        goalConditions: {
            conditions: compileFilters(values.goalFilters || []),
            configuration: values.goalFilters || [],
        },
        isActive: values.isActive,
        lifetimeUnit: 'day',
    };
    if (!payload.recommendationType || !payload.includeRecommendations)
        delete payload.recommendationType;
    if (isDraft)
        payload.isDraft = true;
    return payload;
};
