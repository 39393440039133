var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Modal } from '@/components/Modal/Modal';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Arrow } from '@/icons/Arrow';
import { Cancel } from '@/icons/Cancel';
import { Cancelled } from '@/icons/status/Cancelled';
import { Planned } from '@/icons/status/Planned';
import { formatFromDateToNow } from '@/utils/dates';
import { useShops } from '../FormPurchase/useShops';
import styles from '../FormPurchaseWithDetails/styles.module.scss';
import { FormOrderWithDetails } from './FormOrderWithDetails';
import orderModalStyles from './styles.module.scss';
import { useCancelOrder } from './useCancelOrder';
import { useConfirmOrder } from './useConfirmOrder';
export const FormOrderWithDetailsModal = memo((_a) => {
    var { isOpen, onClose, selectedOrder, setSelectedOrderIndex, selectedOrderIndex, ordersListLength } = _a, props = __rest(_a, ["isOpen", "onClose", "selectedOrder", "setSelectedOrderIndex", "selectedOrderIndex", "ordersListLength"]);
    const { t, i18n } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasPurchasesManage = userHasAccessTo('CLIENTS_PURCHASES_MANAGE');
    const shops = useShops();
    const cancelOrder = useCancelOrder();
    const confirmOrder = useConfirmOrder();
    const handleCancel = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedOrder)
            return;
        setIsSubmitting(true);
        const result = yield cancelOrder(selectedOrder);
        if (result)
            onClose === null || onClose === void 0 ? void 0 : onClose({ cancelled: true });
        setIsSubmitting(false);
    }), [selectedOrder, onClose]);
    const handleConfirm = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedOrder)
            return;
        setIsSubmitting(true);
        const result = yield confirmOrder(selectedOrder);
        if (result)
            onClose === null || onClose === void 0 ? void 0 : onClose({ confirmed: true });
        setIsSubmitting(false);
    }), [selectedOrder, onClose]);
    const orderActive = (selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.status) === 0;
    const orderCanceled = (selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.status) === 2;
    const title = (_jsxs("div", Object.assign({ className: orderModalStyles.title }, { children: [_jsx("div", { children: t('Заказ') }), orderActive && (_jsxs("div", Object.assign({ className: orderModalStyles.status }, { children: [_jsx(Planned, {}), t('Не выкуплен'), ' ', formatFromDateToNow(i18n.language, selectedOrder.executedAt)] }))), orderCanceled && (_jsxs("div", Object.assign({ className: orderModalStyles.status }, { children: [_jsx(Cancelled, {}), t('Отменён')] })))] })));
    const footer = (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: styles.footer }, { children: [_jsxs("div", Object.assign({ className: styles.footerButtons }, { children: [_jsx(Tooltip, Object.assign({ title: !userHasPurchasesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'button', theme: 'danger', icon: _jsx(Cancel, {}), disabled: !userHasPurchasesManage || isSubmitting || orderCanceled, onClick: handleCancel }, { children: t('Отменить заказ') })) })), _jsx(Tooltip, Object.assign({ title: !userHasPurchasesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'button', disabled: !userHasPurchasesManage || isSubmitting || orderCanceled, onClick: handleConfirm }, { children: t('Подтвердить заказ') })) }))] })), _jsxs("div", Object.assign({ className: classNames(styles.footerButtons, styles.footerNavButtons) }, { children: [_jsx(Button, Object.assign({ size: 'large', disabled: selectedOrderIndex === 0, type: 'button', onClick: () => {
                                if (selectedOrderIndex !== null)
                                    setSelectedOrderIndex(selectedOrderIndex - 1);
                            } }, { children: _jsx(Arrow, {}) })), _jsx(Button, Object.assign({ size: 'large', disabled: ordersListLength - 1 === selectedOrderIndex, type: 'button', onClick: () => {
                                if (selectedOrderIndex !== null)
                                    setSelectedOrderIndex(selectedOrderIndex + 1);
                            } }, { children: _jsx(Arrow, {}) }))] }))] })) }));
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, title: title, footer: footer, modalClassName: styles.modal, bodyClassName: styles.modalBody, footerClassName: styles.modalFooter, size: '1024', isForm: true }, { children: _jsx(FormOrderWithDetails, Object.assign({ selectedOrder: selectedOrder, shops: shops }, props)) })));
});
