import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isQuoteNode } from '@lexical/rich-text';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, SELECTION_CHANGE_COMMAND, } from 'lexical';
import { useEffect, useRef, useState } from 'react';
import { EditorToolbar as EditorToolbarComponent } from '@/components/rich-text/EditorToolbar/EditorToolbar';
import { EmojiPopup } from '../../plugins/EmojiPlugin/EmojiPopup';
import { VarsPopup } from '../../plugins/VariablePlugin/VarsPopup';
import { LinkPopup } from '../plugins/LinkPlugin/LinkPopup';
import { OptionsPopup } from './OptionsPopup';
const INIT_SELECT_FORMATS = {
    bold: false,
    italic: false,
    underline: false,
    strikethrough: false,
    code: false,
};
export const Toolbar = ({ isLink, isCreateEmptyLink, variables, varsPopupType, hasSmallVarsPopupIcon = false, hasEmojiSupport, isOpenLinkPopup, handleCloseLinkPopup, isRounded, currency, linkTitle, linkHref, onChangeLinkData, handleCreateLink, onOpenLinkPopup, handleDeleteLink, }) => {
    const [editor] = useLexicalComposerContext();
    const selectFormatsMap = useRef(INIT_SELECT_FORMATS);
    const [selectFormats, setSelectFormats] = useState(selectFormatsMap.current);
    const [isQuote, setIsQuote] = useState(false);
    const onCloseOptionsPopup = () => {
        selectFormatsMap.current = INIT_SELECT_FORMATS;
        setSelectFormats(INIT_SELECT_FORMATS);
    };
    const formatSelectNodes = () => {
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection))
                return onCloseOptionsPopup();
            const nodes = selection.getNodes();
            nodes.forEach((node) => {
                selectFormatsMap.current = Object.assign(Object.assign({}, selectFormatsMap.current), { bold: node.hasFormat('bold') });
                selectFormatsMap.current = Object.assign(Object.assign({}, selectFormatsMap.current), { italic: node.hasFormat('italic') });
                selectFormatsMap.current = Object.assign(Object.assign({}, selectFormatsMap.current), { underline: node.hasFormat('underline') });
                selectFormatsMap.current = Object.assign(Object.assign({}, selectFormatsMap.current), { strikethrough: node.hasFormat('strikethrough') });
                selectFormatsMap.current = Object.assign(Object.assign({}, selectFormatsMap.current), { code: node.hasFormat('code') });
            });
            const firstNodeParents = nodes[0].getParents();
            setIsQuote(firstNodeParents === null || firstNodeParents === void 0 ? void 0 : firstNodeParents.some($isQuoteNode));
            setSelectFormats(selectFormatsMap.current);
        });
    };
    const getFormatsFromSelection = (type, isToggle = false) => {
        if (isToggle) {
            selectFormatsMap.current = Object.assign(Object.assign({}, selectFormatsMap.current), { [type]: !selectFormatsMap.current[type] });
            formatSelectNodes();
            return;
        }
        if (!selectFormatsMap.current[type])
            selectFormatsMap.current = Object.assign(Object.assign({}, selectFormatsMap.current), { [type]: true });
    };
    useEffect(() => {
        editor.registerCommand(SELECTION_CHANGE_COMMAND, () => {
            formatSelectNodes();
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return (_jsx(EditorToolbarComponent, { emojiPopup: hasEmojiSupport ? _jsx(EmojiPopup, {}) : undefined, varsPopup: _jsx(VarsPopup, { type: varsPopupType, variables: variables, currency: currency }), hasSmallVarsPopupIcon: hasSmallVarsPopupIcon, linkPopup: _jsx(LinkPopup, { isCreateEmptyLink: isCreateEmptyLink, handleDeleteLink: handleDeleteLink, handleCreateLink: handleCreateLink, onChangeLinkData: onChangeLinkData, onClose: handleCloseLinkPopup, linkHref: linkHref, linkTitle: linkTitle }), optionsPopup: _jsx(OptionsPopup, { setIsQuote: setIsQuote, getFormatsFromSelection: getFormatsFromSelection, isBold: selectFormats.bold, isItalic: selectFormats.italic, isSpoiler: selectFormats.code, isUnderline: selectFormats.underline, isStrikethrough: selectFormats.strikethrough, isQuote: isQuote }), linkActive: isLink, isOpenLinkPopup: isOpenLinkPopup, onOpenLinkPopup: onOpenLinkPopup, onCloseLinkPopup: handleCloseLinkPopup, onCloseOptionsPopup: onCloseOptionsPopup, formatSelectNodes: formatSelectNodes, isRounded: isRounded }));
};
