import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { useCount } from './hooks/useCount';
import { useExportActions } from './hooks/useExportActions';
import { useFiltersState } from './hooks/useFiltersState';
import { useModalState } from './hooks/useModalState';
import { useOrders } from './hooks/useOrders';
import { usePaginationState } from './hooks/usePaginationState';
import { usePurchases } from './hooks/usePurchases';
import { useSearchState } from './hooks/useSearchState';
import { useSelectedOrder } from './hooks/useSelectedOrder';
import { useSelectedPurchase } from './hooks/useSelectedPurchase';
import { useTabsState } from './hooks/useTabsState';
import { PurchasesContext } from './PurchasesContext';
export const PurchasesProvider = ({ children, }) => {
    const { brand, user } = useCurrentUser();
    const tabs = useTabsState();
    const { pagination, resetPagination } = usePaginationState();
    const search = useSearchState({ onApply: resetPagination });
    const { filters, compiled } = useFiltersState({ onUpdate: resetPagination });
    const count = useCount({
        filters: compiled,
        searchText: search.searchText,
    });
    const purchases = usePurchases({
        filters: compiled,
        searchText: search.searchText,
    });
    const orders = useOrders({
        filters: compiled,
        searchText: search.searchText,
    });
    const exportData = useExportActions({
        filters: compiled,
        searchText: search.searchText,
    });
    const value = {
        tabs,
        pagination,
        search,
        filters,
        data: { count, purchases, orders },
        selectedPurchase: useSelectedPurchase(purchases.items),
        selectedOrder: useSelectedOrder(orders.items),
        modals: useModalState(),
        exportData,
    };
    if (!brand || !user)
        return null;
    return (_jsx(PurchasesContext.Provider, Object.assign({ value: value }, { children: children })));
};
