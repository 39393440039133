import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { MailingRewardsInput } from '@/components/MailingRewardsInput/MailingRewardsInput';
import { MailingRewardsPanels, } from '@/components/MailingRewardsInput/MailingRewardsPanels';
export const FormMailingRewards = ({ offers, promocodes, giftCards, currency, labelWidth, }) => {
    var _a;
    const { control, formState, setValue, watch } = useFormContext();
    const { errors, isSubmitted } = formState;
    const rewards = watch('rewards') || {};
    const hasValue = Object.keys(rewards).length > 0;
    const rewardsErrors = (_a = errors.rewards) === null || _a === void 0 ? void 0 : _a.message;
    const validate = (value) => {
        var _a;
        const errors = [];
        if (typeof rewards.bonusesAmount !== 'undefined') {
            const bonusesAmount = Number(value === null || value === void 0 ? void 0 : value.bonusesAmount) || 0;
            const bonusesLifetime = Number(value === null || value === void 0 ? void 0 : value.bonusesLifetime) || 0;
            if (!bonusesAmount)
                errors.push('bonusesAmount');
            if (!bonusesLifetime || bonusesLifetime > 999)
                errors.push('bonusesLifetime');
        }
        (_a = value === null || value === void 0 ? void 0 : value.offers) === null || _a === void 0 ? void 0 : _a.forEach((offer, i) => {
            if (offer.expireInDays !== '') {
                const expireInDays = Number(offer.expireInDays) || 0;
                if (!expireInDays || expireInDays > 999)
                    errors.push(`offer-${i}`);
            }
        });
        return errors.length > 0 ? errors.join(',') : true;
    };
    return (_jsxs(_Fragment, { children: [hasValue && (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth }, { children: _jsx(MailingRewardsPanels, { value: rewards, onChange: (value) => {
                        setValue('rewards', value, {
                            shouldValidate: isSubmitted,
                            shouldDirty: true,
                        });
                    }, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency, bonusesAmountId: 'rewards.bonusesAmount', hasBonusesAmountError: rewardsErrors === null || rewardsErrors === void 0 ? void 0 : rewardsErrors.includes('bonusesAmount'), bonusesLifetimeId: 'rewards.bonusesLifetime', hasBonusesLifetimeError: rewardsErrors === null || rewardsErrors === void 0 ? void 0 : rewardsErrors.includes('bonusesLifetime'), offerExpireInDaysName: 'rewards.offers.expireInDays', hasOfferExpireInDaysError: (i) => rewardsErrors === null || rewardsErrors === void 0 ? void 0 : rewardsErrors.includes(`offer-${i}`) }) }))), _jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth }, { children: _jsx(Controller, { name: 'rewards', control: control, rules: { validate }, render: ({ field }) => (_jsx(MailingRewardsInput, { value: field.value || {}, onChange: (value) => {
                            setValue('rewards', value, {
                                shouldValidate: isSubmitted,
                                shouldDirty: true,
                            });
                        }, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency, shouldRenderPanels: false, buttonRef: field.ref })) }) }))] }));
};
