import { jsx as _jsx } from "react/jsx-runtime";
import { $getNodeByKey, DecoratorNode, } from 'lexical';
import { textFormatter, wrapElementWith } from '../../utils/textFormatter';
import { VariableComponent } from './VariableComponent';
export class VariableNode extends DecoratorNode {
    constructor(id, label, payload, formats, key) {
        super(key);
        this.onSetParam = (code, value, editor) => {
            editor.update(() => {
                const node = $getNodeByKey(this.getKey());
                if (node !== null && $isVariableNode(node))
                    node.setParam(code, value);
            });
        };
        this.__id = id;
        this.__label = label;
        this.__payload = payload;
        this.__formats = formats;
    }
    static getType() {
        return 'variable';
    }
    static clone(node) {
        return new VariableNode(node.__id, node.__label, node.__payload, node.__formats, node.__key);
    }
    // rendering methods ---------------------------------------------------------
    decorate(editor) {
        return (_jsx(VariableComponent, Object.assign({ formats: this.__formats, payload: this.__payload, onSetParam: (code, value) => {
                this.onSetParam(code, value, editor);
            } }, { children: this.__label })));
    }
    setParam(code, value) {
        const writable = this.getWritable();
        if (!writable.__payload.params)
            writable.__payload.params = {};
        writable.__payload.params[code] = value;
    }
    setFormat(format) {
        const writable = this.getWritable();
        if (writable.__formats[format]) {
            writable.__formats[format] = false;
            return;
        }
        writable.__formats[format] = true;
    }
    hasFormat(format) {
        return !!this.__formats[format];
    }
    isInline() {
        return true;
    }
    isIsolated() {
        return true;
    }
    isKeyboardSelectable() {
        return true;
    }
    // DOM methods ---------------------------------------------------------------
    createDOM() {
        const element = textFormatter('span', this);
        element.addEventListener('click', function () {
            const range = document.createRange();
            range.selectNode(element);
            const selection = window.getSelection();
            selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
            selection === null || selection === void 0 ? void 0 : selection.addRange(range);
        });
        return element;
    }
    updateDOM() {
        return true;
    }
    exportDOM() {
        let element = textFormatter('span', this);
        element.textContent = `!${this.__id}`;
        if (this.hasFormat('bold'))
            element = wrapElementWith(element, 'b');
        if (this.hasFormat('italic'))
            element = wrapElementWith(element, 'i');
        if (this.hasFormat('strikethrough'))
            element = wrapElementWith(element, 's');
        if (this.hasFormat('underline'))
            element = wrapElementWith(element, 'u');
        // тут кейс с тегом code используем как спойлер в телеграме
        if (this.hasFormat('code'))
            element = wrapElementWith(element, 'tg-spoiler');
        return {
            element,
        };
    }
    // JSON methods --------------------------------------------------------------
    exportJSON() {
        return {
            version: 1,
            type: 'variable',
            id: this.__id,
            label: this.__label,
            payload: this.__payload,
        };
    }
    static importJSON(serializedNode) {
        const node = $createVariableNode({
            id: serializedNode.id,
            label: serializedNode.label,
            key: serializedNode.payload.key,
            params: serializedNode.payload.params,
            parameters: serializedNode.payload.parameters,
        }, {});
        return node;
    }
    // Text methods --------------------------------------------------------------
    // Проверить использование метода
    getTextContent() {
        return this.__label;
    }
}
export function $createVariableNode(variable, formats = {}) {
    const node = new VariableNode(variable.id, variable.label, {
        key: variable.key,
        params: variable.params,
        parameters: variable.parameters,
    }, formats);
    return node;
}
export function createSerializedVariableNode(variable) {
    return {
        version: 1,
        type: 'variable',
        id: variable.id,
        label: variable.label,
        payload: {
            key: variable.key,
            params: variable.params,
            parameters: variable.parameters,
        },
    };
}
export function $isVariableNode(node) {
    return node instanceof VariableNode;
}
