import { jsx as _jsx } from "react/jsx-runtime";
import { Pagination } from '@/components/Pagination/Pagination';
import { usePurchasesPage } from './context/usePurchasesPage';
export const PurchasesPagination = () => {
    const { tabs, data, pagination } = usePurchasesPage();
    if (tabs.activeTab === 'purchases' && !data.count.purchase)
        return null;
    if (tabs.activeTab === 'orders' && !data.count.order)
        return null;
    return tabs.activeTab === 'purchases' ? (_jsx(Pagination, { total: data.count.purchase, limit: pagination.purchases.limit, offset: pagination.purchases.offset, onChangeOffset: pagination.purchases.setOffset })) : (_jsx(Pagination, { total: data.count.order, limit: pagination.orders.limit, offset: pagination.orders.offset, onChangeOffset: pagination.orders.setOffset }));
};
