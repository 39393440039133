import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { FormOrderWithDetailsModal } from '@/forms/FormOrderWithDetails/FormOrderWithDetailsModal';
import { usePurchasesPage } from './context/usePurchasesPage';
export const OrderDetailsModal = () => {
    const { currency } = useCurrentUser();
    const { data, pagination, selectedOrder, modals } = usePurchasesPage();
    return (_jsx(FormOrderWithDetailsModal, { isOpen: modals.orders.detailsModal.isOpen, onClose: (result) => {
            modals.orders.detailsModal.close();
            if ((result === null || result === void 0 ? void 0 : result.confirmed) || (result === null || result === void 0 ? void 0 : result.cancelled))
                if (pagination.orders.offset !== 0)
                    pagination.orders.resetOffset();
                else
                    data.orders.refetch();
        }, currency: currency, selectedOrder: selectedOrder.data, setSelectedOrderIndex: selectedOrder.setIndex, selectedOrderIndex: selectedOrder.index, ordersListLength: data.orders.items.length }));
};
