import { useGetPurchaseCountQuery } from '@/api/query/purchases';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useCount = ({ filters, searchText, }) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data, isLoading, isFetching, refetch } = useGetPurchaseCountQuery({ filters, searchText, periodStart, periodEnd }, { refetchOnMountOrArgChange: true });
    return {
        purchase: typeof data === 'undefined' || typeof data === 'number'
            ? data || 0
            : data.purchase,
        order: typeof data === 'undefined' || typeof data === 'number' ? 0 : data.order,
        isLoading,
        isFetching,
        refetch,
    };
};
