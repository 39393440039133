import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Button } from '@/components/Button2/Button';
import { DateInput } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { Input } from '@/components/Input/Input';
import { TextArea } from '@/components/Input/TextArea';
import { Mail } from '@/icons/Mail';
import { emailPattern, isValidEmail } from '@/utils/email';
import { generatePassword } from '@/utils/generatePassword';
import { numberRound } from '@/utils/numbers';
import { useCopyToClipboard } from '~/src/hooks/useCopyToClipboard';
import styles from './styles.module.scss';
const PASSWORD_ADDITION_TYPES = {
    SET_BY_USER: 'setByUser',
    SET_MANUALLY: 'setManually',
    NO_CHANGE: 'noChange',
    SEND_UPDATE_LINK: 'sendUpdateLink',
};
const PASSWORD_ADDITION_CREATE_OPTIONS = [
    {
        id: PASSWORD_ADDITION_TYPES.SET_BY_USER,
        label: 'задает пользователь',
    },
    {
        id: PASSWORD_ADDITION_TYPES.SET_MANUALLY,
        label: 'задать вручную',
    },
];
const PASSWORD_ADDITION_EDIT_OPTIONS = [
    {
        id: PASSWORD_ADDITION_TYPES.NO_CHANGE,
        label: 'без изменений',
    },
    {
        id: PASSWORD_ADDITION_TYPES.SEND_UPDATE_LINK,
        label: 'отправить ссылку для обновления',
    },
    {
        id: PASSWORD_ADDITION_TYPES.SET_MANUALLY,
        label: 'задать новый вручную',
    },
];
export const FormUsers = memo(({ id, onSubmit, setHasFormChanged, defaultValues = {}, roles, isEdit }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation();
    const { control, handleSubmit, formState, setValue, watch } = useForm({
        defaultValues: Object.assign(Object.assign({}, defaultValues), { operatorRoleId: (_a = defaultValues.operatorRole) === null || _a === void 0 ? void 0 : _a.id }),
    });
    const { errors, isSubmitted, isSubmitting, dirtyFields } = formState;
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged(!isSubmitting && !!Object.keys(dirtyFields).length);
    }, [isSubmitting, dirtyFields]);
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) && !isEdit ? t('FIELD_REQUIRED_ERROR') : true;
    const roleListOptions = roles.map((role) => ({
        value: +role.id,
        label: role.name,
    }));
    const email = watch('email');
    const password = watch('password');
    const username = watch('username');
    const emailValid = isValidEmail(email || '');
    const copyToClipboard = useCopyToClipboard({ showToast: true });
    const passwordAutocompleteOptions = isEdit
        ? PASSWORD_ADDITION_EDIT_OPTIONS
        : PASSWORD_ADDITION_CREATE_OPTIONS;
    const [passwordAdditionType, setPasswordAdditionType] = useState(passwordAutocompleteOptions[0]);
    const isPasswordSetManually = passwordAdditionType.id === PASSWORD_ADDITION_TYPES.SET_MANUALLY;
    const isPasswordSendUpdateLink = passwordAdditionType.id === PASSWORD_ADDITION_TYPES.SEND_UPDATE_LINK;
    const isPasswordSetByUser = passwordAdditionType.id === PASSWORD_ADDITION_TYPES.SET_BY_USER;
    const isRequiredEmailField = isPasswordSendUpdateLink || isPasswordSetByUser;
    useEffect(() => {
        if (isRequiredEmailField)
            return setValue('isSendToEmail', true);
        if (!isRequiredEmailField || !emailValid)
            setValue('isSendToEmail', false);
    }, [isRequiredEmailField, emailValid]);
    const userDataForClipboard = username && password
        ? `${t('Логин')}: ${username} ${t('Пароль')}: ${password}`
        : '';
    return (_jsxs("form", Object.assign({ id: id, onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(FormFieldset, Object.assign({ title: t('Для входа в систему') }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Логин'), labelFor: 'username', controlSize: '250', help: (_b = errors.username) === null || _b === void 0 ? void 0 : _b.message, isRequired: true, hasError: !!errors.username }, { children: _jsx(Controller, { name: 'username', control: control, rules: {
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                pattern: {
                                    value: /^[A-Z0-9@._+-]+$/i,
                                    message: t('только латиница'),
                                },
                                validate: validateTrimValue,
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'username', placeholder: t('введите логин'), hasError: !!errors.username, autoFocus: true }))) }) })), _jsx(FormFieldRow, Object.assign({ isRequired: isRequiredEmailField, label: t('Email'), labelFor: 'email', controlSize: '250', help: (_c = errors.email) === null || _c === void 0 ? void 0 : _c.message, hasError: !!errors.email }, { children: _jsx(Controller, { name: 'email', control: control, rules: {
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                                required: {
                                    value: isRequiredEmailField,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                pattern: {
                                    value: emailPattern,
                                    message: t('неверный Email'),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'email', type: 'email', placeholder: t('name@mail.com'), leftIcon: _jsx(Mail, { className: styles.mailInputIcon }), hasError: !!errors.email }))) }) })), _jsx(FormFieldRow, Object.assign({ isRequired: isPasswordSetManually, label: t('Пароль'), labelFor: 'password', controlSize: '250', help: (isPasswordSendUpdateLink || isPasswordSetByUser) &&
                            t('На почту пользователя отправится ссылка на создание пароля'), helpSize: '250', helpClassName: styles.passwordTypeHelpText }, { children: _jsx(Autocomplete, { className: styles.shortAutocomplete, value: passwordAutocompleteOptions.find((item) => item.id === passwordAdditionType.id) || null, onChange: (type) => {
                                if (type)
                                    setPasswordAdditionType(type);
                            }, options: passwordAutocompleteOptions, getOptionKey: (option) => option.id, getOptionLabel: (option) => t(option.label), hasResetButton: false, asSelect: true }) })), isPasswordSetManually && (_jsxs(_Fragment, { children: [_jsx(FormFieldRow, Object.assign({ controlSize: '250', help: (_d = errors.password) === null || _d === void 0 ? void 0 : _d.message, hasError: !!errors.password }, { children: _jsx(Controller, { name: 'password', control: control, rules: {
                                        maxLength: {
                                            value: 200,
                                            message: t('FIELD_MAX_LENGTH_ERROR', {
                                                amount: numberRound(200),
                                                count: 200,
                                            }),
                                        },
                                        required: {
                                            value: !isEdit,
                                            message: t('FIELD_REQUIRED_ERROR'),
                                        },
                                        validate: validateTrimValue,
                                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'password', placeholder: isEdit ? '********' : t('введите пароль'), rightButton: _jsx(Button, Object.assign({ isOutlined: true, onClick: () => {
                                                setValue('password', generatePassword(), {
                                                    shouldValidate: isSubmitted,
                                                });
                                            }, type: 'button' }, { children: t('сгенерировать') })), hasError: !!errors.password }))) }) })), _jsx(FormFieldRow, Object.assign({ controlSize: '250' }, { children: _jsx(Button, Object.assign({ size: 'full-width', disabled: !userDataForClipboard, type: 'button', onClick: () => copyToClipboard(userDataForClipboard) }, { children: t('Скопировать данные для входа') })) }))] }))] })), _jsxs(FormFieldset, Object.assign({ title: t('Для отображения в системе') }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Имя'), labelFor: 'name', controlSize: '250', help: (_e = errors.name) === null || _e === void 0 ? void 0 : _e.message, hasError: !!errors.name }, { children: _jsx(Controller, { name: 'name', control: control, rules: {
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', placeholder: t('введите имя пользователя'), hasError: !!errors.name }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Роль (уровень доступа)'), labelFor: 'role', controlSize: '250', help: (_f = errors.operatorRoleId) === null || _f === void 0 ? void 0 : _f.message, isRequired: true, hasError: !!errors.operatorRoleId }, { children: _jsx(Controller, { name: 'operatorRoleId', rules: {
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                            }, control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ className: styles.shortAutocomplete }, field, { id: 'operatorRoleId', placeholder: t('выберите роль'), value: roleListOptions.find((option) => option.value === +field.value) || null, onChange: (role) => {
                                    setValue('operatorRoleId', role === null || role === void 0 ? void 0 : role.value, {
                                        shouldValidate: isSubmitted,
                                        shouldDirty: true,
                                    });
                                }, options: roleListOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasError: !!errors.operatorRoleId, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Доступ открыт до'), labelFor: 'availableTo', controlSize: '250', help: (_g = errors.availableTo) === null || _g === void 0 ? void 0 : _g.message, hasError: !!errors.availableTo }, { children: _jsx(Controller, { name: 'availableTo', control: control, render: ({ field }) => {
                                var _a;
                                return (_jsx(DateInput, { id: 'availableTo', placeholderText: t('дд.мм.гггг'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                        setValue('availableTo', date ? moment(date).format() : '', {
                                            shouldValidate: true,
                                        });
                                    }, hasError: !!((_a = errors.availableTo) === null || _a === void 0 ? void 0 : _a.message) }));
                            } }) })), _jsx(FormFieldRow, Object.assign({ label: t('Комментарий'), labelFor: 'comment', controlSize: '360', help: t('видят только администраторы') }, { children: _jsx(Controller, { name: 'comment', control: control, render: ({ field }) => (_jsx(TextArea, Object.assign({}, field, { id: 'comment', placeholder: t('введите комментарий'), rows: 3 }))) }) }))] }))] })));
});
