import { api } from './';
export const ordersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrderList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/order/list',
                body,
            }),
            providesTags: ['OrderList'],
        }),
        getOrder: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/order/get/${id}`,
            }),
            providesTags: ['Order'],
        }),
        cancelOrder: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/order/cancel',
                body,
            }),
            invalidatesTags: ['PurchaseCount', 'PurchaseList', 'OrderList', 'Order'],
        }),
        confirmOrder: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/order/confirm',
                body,
            }),
            invalidatesTags: ['PurchaseCount', 'PurchaseList', 'OrderList', 'Order'],
        }),
    }),
});
export const { useGetOrderListQuery, useGetOrderQuery, useCancelOrderMutation, useConfirmOrderMutation, } = ordersApi;
